import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";
import Pagination from "../components/newComponents/pagination";
import Card from "../components/newComponents/card";
import SectionHead from "../components/newComponents/sectionHead";

export const query = graphql`
  
  query ProjectsListingQuery($limit: Int, $skip: Int) {
    posts: allCraftProjectsProjectsEntry(sort: {fields: postDate, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        title
        displayTitle
        slug
        uri
        postDate
        summary
        featureImage {
          ...AssetFragment  
        }
      }
    }

    entry: craftProjectsIndexProjectsIndexEntry {
      title
      displayTitle
      summary
    }
  }
`;

const ProjectsResult = ({data: {entry, posts}, pageContext}) => (
    
  <Layout pageContext={pageContext}>
    <Seo title={entry.displayTitle} />
      <div className="main">
        <section className="section-overview" style={{paddingBottom: 0}}>
          <div className="shell">
            <SectionHead title="Projects" subtitle="OUR VALUE UNLOCKED PROJECTS" />
          </div>
        </section>
        <section className="section-listing section-listing--secondary js-section-listing" style={{paddingTop: 0}}>
          <div className="shell">
            <div className="boxes swiper-container js-slider-boxes-projects">
              <ul>
                {posts.nodes.map((item, i) => (
                  <Card key={`projects-listing-${i}`} image={item.featureImage[0]} title={item.title} subtitle={item.summary} href={`/${item.uri}`}/>
                ))}
              </ul>
            </div>
            <div className="section-boxes section-boxes--news-secondary">
              {pageContext.totalPages > 1 &&
                <Pagination nextUrl={pageContext.nextUrl} prevUrl={pageContext.prevUrl} currentPage={pageContext.currentPage} base="/projects" totalPages={pageContext.totalPages}/>
              }
            </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default ProjectsResult;